import Vue from "vue"
import axios from "axios"
import Toasted from "vue-toasted"
Vue.use(Toasted, { duration: 5000 })

export default {
  success(message) {
    Vue.toasted.show(message ? message : "Success", {
      type: "success",
    })
  },
  info(message) {
    Vue.toasted.show(message, {
      type: "info",
    })
  },
  error(err) {
    if (err.response && err.response.status === 401) {
      localStorage.clear()
      delete axios.defaults.headers.common.authorization
      window.location.href = "/login"
    }

    const responseError =
      err?.response?.data?.errors ??
      err?.response?.data?.error ??
      err?.response?.data?.data?.errors ??
      err?.message

    if (responseError) {
      if (responseError instanceof Object) {
        let errorMessage = ""
        Object.keys(responseError).map((key) => {
          errorMessage = errorMessage + responseError[key] + `<br>`
        })
        Vue.toasted.show(errorMessage, {
          type: "error",
        })
      } else {
        Vue.toasted.show(responseError, {
          type: "error",
        })
      }
    } else {
      const defaultMessage =
        "Something went wrong with the server. Please contact the administration team"
      Vue.toasted.show(defaultMessage, {
        type: "error",
      })
    }
  },
  customError(error) {
    Vue.toasted.show(error, {
      type: "error",
    })
  },
}
